import HomeHeader from "../components/HomeHeader";
import { Link, Outlet } from 'react-router-dom'

const AuthLayout = ({ children }) => {
  return (
    <div>
      <HomeHeader/>
      <Outlet/>
      <section className={`section  pt-[48px] pb-[24px]`}>
        <div className="container">
          <div className="w-full flex flex-col md:flex-row md:items-center  md:justify-between relative">
            <div className="flex flex-col">
              <div>
                <img src="/images/logo.png" className="h-[8vw] md:h-[57px] w-auto mb-[24px]" alt=""/>
              </div>
              <div className="text-[4vw] md:text-base">
                助你輕鬆轉型 <br/>
                讓生意有更多可能性。
              </div>
              <div className="w-full mt-[1vw] md:mt-[16px] text-xs">
                ©2024 Oshopoo Limite。<Link className="link" to='/legal-agreement' target="_blank">條款及細則</Link>&nbsp;
                <Link className="link" to='/privacy-policy' target="_blank">私隱條款</Link>
              </div>
            </div>

            <div className="flex flex-col md:items-end">
              <div className="h-[57px] md:mb-[24px] flex items-center text-[4vw] md:text-base">
                <Link to="/fun" className="mr-[40px]">
                  功能套餐
                </Link>
                <span>
                幫助中心
              </span>
              </div>
              <div className="flex">
                <Link to='https://www.instagram.com/oshopoo_com?igsh=MXkwNXo1bGx6YjA1aA%3D%3D&utm_source=qr'
                      target="_blank">
                  <img src="/images/instagram-logo.png" className="w-[40px] mr-[4vw] md:mx-[20px]" alt=""
                  />
                </Link>
                <Link to="https://wa.me/85260222423" target="_blank">
                  <img src="/images/call.png" className="w-[40px] mr-[4vw] md:mx-[20px]" alt=""/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="flex justify-center items-center h-[100px]  w-full bg-[#FCFCFC] text-center ">
        <span className="text-xs my-2 leading-5">Always believe that <br className="md:hidden"/> something wonderful is about to happen <br/>永遠相信美好的事情即將發生</span>
      </footer>
    </div>
  );
};

export default AuthLayout;
