export default function Fun() {

  const arr = [
    {
      title: '網上商店',
      value: ''
    },
    {
      title: '平台交易抽成',
      value: '0%'
    },
    {
      title: '會員註冊',
      value: ''
    },
    {
      title: '會員信息購買紀錄等等',
      value: ''
    },
    {
      title: '會員電子錢包',
      value: ''
    },
    {
      title: '會員儲值及支付',
      value: ''
    },
    {
      title: '商品件數',
      value: '無限'
    },
    {
      title: '商品剩少量庫存提醒',
      value: ''
    },
    {
      title: 'SKU設置',
      value: '無限'
    },
    {
      title: '批量修改價格/庫存',
      value: ''
    },
    {
      title: '優惠券',
      value: '無限'
    },
    {
      title: '折扣券/滿減券/禮品券',
      value: ''
    },
    {
      title: '快速加入購物車',
      value: ''
    }, {
      title: '預約系統',
      value: ''
    },
    {
      title: '自訂每個月預約時間表',
      value: ''
    }, {
      title: '經網店售賣及預約服務項目',
      value: ''
    }, {
      title: '在平台獨立網址',
      value: ''
    }, {
      title: '平台網址SSL',
      value: ''
    }, {
      title: '頁面SEO設置',
      value: ''
    }, {
      title: '香港熱門支付',
      value: ''
    }, {
      title: '網站綁定自擁有網址',
      value: '額外加購'
    }, {
      title: '自己品牌信息發短信抬頭',
      value: '額外加購'
    }, {
      title: '自己品牌信息發郵箱抬頭',
      value: '額外加購'
    }, {
      title: '網站綁定自擁有網址SSL',
      value: '額外加購'
    },

  ]
  return <>
    <section className={`section bg-[#0A282A]`}>
      <div className={`container  flex flex-col items-center pb-[58px] text-center text-white`}>
        <h3 className="font-bold text-4xl mt-[48px] mb-[20px] text-white" style={{ fontFamily: 'DINPro-Bold, DINPro' }}>
          功能套餐</h3>
        <span className="my-[8px]">
        沒有功能分層，沒有等級分層，沒有不同價格，全部功能奉上 <br/>
        助你輕鬆轉型，讓生意有更多可能性
      </span>
      </div>
    </section>
    <section className={`section bg-white`}>
      <div className={`container  flex flex-col items-center pb-[58px] text-center`}>
        <h3 className="font-bold text-4xl mt-[48px] mb-[10px]" style={{ fontFamily: 'DINPro-Bold, DINPro' }}>
          具體大功能</h3>
        <span className="my-[8px]">
        註冊使用，去發現更多功能啦
      </span>

        <div className={` w-full md:w-[622px] text-black`}>
          {
            arr.map((item, i) => {
              return <div className="listItem flex justify-between w-full py-[18px] px-[32px] items-center" key={i}>
                <div>{item.title}</div>
                <div>{item.value || <img src="/images/right.png" className="w-[5.5vw] md:w-[24px]"/>
                }</div>
              </div>
            })
          }
        </div>

        <div className="w-fit flex flex-col  items-center mt-[50px]">
          <button onClick={() => window.location.href = 'http://admin.myoshopoo.com/register?username='}
                  className="button flex items-center px-[30px] ml-[20px] tracking-wider rounded-[8px] h-[50px] bg-[#2c695d]
            text-white ">立即免費試用14天
          </button>
          <span className="text-sm  my-2">免費試用14日（*無需提供信用卡資料）</span>
        </div>
      </div>
    </section>
  </>
}
