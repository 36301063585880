import './server.css'
export default function Legal() {

  return <>
    <section className={`section bg-[#0A282A]`}>
      <div className={`container  flex flex-col items-center pb-[58px] text-center text-white`}>
        <h3 className="font-bold text-4xl my-[60px] text-white" style={{ fontFamily: 'DINPro-Bold, DINPro' }}>
          Terms of Service</h3>
      </div>
    </section>
    <section className={`section bg-white`}>
      <div className={`container `}>
        <div className="py-[20px] md:py-[58px]" dangerouslySetInnerHTML={{
          __html: `<div className="Section0" style="layout-grid:19.3500pt;">
          <h2 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;
text-align:center;line-height:130%;"><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Bold;mso-fareast-font-family:微软雅黑;
line-height:130%;color:rgb(85,85,85);letter-spacing:0.0000pt;
mso-ansi-font-weight:bold;mso-ansi-font-style:normal;font-size:25.5000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span></b></h2>
          <h3><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Bold;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;">Section A – Overview</span></b><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">These Terms of Service (“Terms”) govern your use of and access to our Services, which include our website, our mobile applications, our application programming interface, notifications, and all other information and content that appears therein (collectively, our “Platform”).</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">By using and/or visiting our Platform, you agree to these Terms. Use of our Platform constitutes acceptance of these Terms and forms a legally binding contract between you and Oshopoo in relation to your access to and use of our Platform. If you use our Platform on behalf of an entity, you will be deemed to have the authority to act on behalf of that entity and agree to these Terms on that entity's behalf.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In addition to these Terms, Customers please review Section B – Customer Terms of Service for additional terms that apply to your use of our Platform. Merchants please review Section C – Merchant Terms of Service for additional terms that apply to your use of our Platform.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">1. Definition and Interpretation</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In these Terms, the following definitions apply:</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <table className="MsoTableGrid"
                 style="border-collapse:collapse;margin-left:5.4000pt;mso-table-layout-alt:fixed;
            border:none;mso-border-left-alt:0.7500pt solid rgb(203,205,209);mso-border-top-alt:0.7500pt solid
            rgb(203,205,209);
            mso-border-right-alt:0.7500pt solid rgb(203,205,209);mso-border-bottom-alt:0.7500pt solid
            rgb(203,205,209);mso-border-insideh:0.7500pt solid rgb(203,205,209);
            mso-border-insidev:0.7500pt solid rgb(203,205,209);mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;">
            <tr>
              <td style="width:99.0000pt;padding:5.4000pt 5.4000pt 5.4000pt 0.0000pt ;border-left:1.0000pt solid rgb(102,102,102);
              mso-border-left-alt:0.0000pt solid rgb(102,102,102);border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
              border-top:1.0000pt solid rgb(102,102,102);mso-border-top-alt:0.0000pt solid rgb(102,102,102);border-bottom:1.0000pt solid rgb(236,236,236);
              mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);">
                <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:left;
line-height:130%;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.0000pt;mso-ansi-font-weight:bold;mso-ansi-font-style:normal;
font-size:11.0000pt;mso-font-kerning:1.0000pt;">About us/Oshopoo:</span></b><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
              <td style="width:397.5000pt;padding:5.4000pt 0.0000pt 5.4000pt 5.4000pt ;border-left:none;
            mso-border-left-alt:none;border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
            border-top:1.0000pt solid rgb(102,102,102);mso-border-top-alt:0.0000pt solid rgb(102,102,102);border-bottom:1.0000pt solid rgb(236,236,236);
            mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);">
                <p className="MsoNormal" style="margin-top:8.0000pt;margin-right:0.0000pt;margin-bottom:8.0000pt;
margin-left:0.0000pt;text-align:left;line-height:133%;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:11.0000pt;
mso-font-kerning:1.0000pt;">Oshopoo Limited, a company registered in Hong Kong https://www.Oshopoo.com</span><span
                  style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
            </tr>
            <tr>
              <td style="width:99.0000pt;padding:5.4000pt 5.4000pt 5.4000pt 0.0000pt ;border-left:1.0000pt solid rgb(102,102,102);
          mso-border-left-alt:0.0000pt solid rgb(102,102,102);border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
          border-top:1.0000pt solid rgb(236,236,236);mso-border-top-alt:0.0000pt solid rgb(236,236,236);border-bottom:1.0000pt solid rgb(236,236,236);
          mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);">
                <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:left;
line-height:130%;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.0000pt;mso-ansi-font-weight:bold;mso-ansi-font-style:normal;
font-size:11.0000pt;mso-font-kerning:1.0000pt;">You/User:</span></b><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
              <td style="width:397.5000pt;padding:5.4000pt 0.0000pt 5.4000pt 5.4000pt ;border-left:none;
          mso-border-left-alt:none;border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
          border-top:1.0000pt solid rgb(236,236,236);mso-border-top-alt:0.0000pt solid rgb(236,236,236);border-bottom:1.0000pt solid rgb(236,236,236);
          mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);">
                <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:left;
          line-height:130%;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
          letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:11.0000pt;
          mso-font-kerning:1.0000pt;">Any party visiting or using our Platform, whether as a merchant, customer or casual viewer.</span><span
                  style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
          font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
            </tr>
            <tr>
              <td style="width:99.0000pt;padding:5.4000pt 5.4000pt 5.4000pt 0.0000pt ;border-left:1.0000pt solid rgb(102,102,102);
          mso-border-left-alt:0.0000pt solid rgb(102,102,102);border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
          border-top:1.0000pt solid rgb(236,236,236);mso-border-top-alt:0.0000pt solid rgb(236,236,236);border-bottom:1.0000pt solid rgb(236,236,236);
          mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);">
                <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:left;
line-height:130%;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.0000pt;mso-ansi-font-weight:bold;mso-ansi-font-style:normal;
font-size:11.0000pt;mso-font-kerning:1.0000pt;">merchant:</span></b><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
              <td style="width:397.5000pt;padding:5.4000pt 0.0000pt 5.4000pt 5.4000pt ;border-left:none;
          mso-border-left-alt:none;border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
          border-top:1.0000pt solid rgb(236,236,236);mso-border-top-alt:0.0000pt solid rgb(236,236,236);border-bottom:1.0000pt solid rgb(236,236,236);
          mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);">
                <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:left;
          line-height:130%;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
          letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:11.0000pt;
          mso-font-kerning:1.0000pt;">Business entities and/or individuals, whether paying or non-paying users of our Platform, who create their online stores to offer products or services through our Platform.</span><span
                  style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
          font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
            </tr>
            <tr>
              <td style="width:99.0000pt;padding:5.4000pt 5.4000pt 5.4000pt 0.0000pt ;border-left:1.0000pt solid rgb(102,102,102);
          mso-border-left-alt:0.0000pt solid rgb(102,102,102);border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
          border-top:1.0000pt solid rgb(236,236,236);mso-border-top-alt:0.0000pt solid rgb(236,236,236);border-bottom:1.0000pt solid rgb(236,236,236);
          mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);
           border-bottom:1.0000pt solid rgb(102,102,102);">
                <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:left;
line-height:130%;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.0000pt;mso-ansi-font-weight:bold;mso-ansi-font-style:normal;
font-size:11.0000pt;mso-font-kerning:1.0000pt;">customer:</span></b><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
              <td style="width:397.5000pt;padding:5.4000pt 0.0000pt 5.4000pt 5.4000pt ;border-left:none;
          mso-border-left-alt:none;border-right:1.0000pt solid rgb(102,102,102);mso-border-right-alt:0.0000pt solid rgb(102,102,102);
          border-top:1.0000pt solid rgb(236,236,236);mso-border-top-alt:0.0000pt solid rgb(236,236,236);border-bottom:1.0000pt solid rgb(236,236,236);
          mso-border-bottom-alt:1.0000pt solid rgb(236,236,236);background:rgb(255,255,255);
          border-bottom:1.0000pt solid rgb(102,102,102);">
                <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:left;
          line-height:130%;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(102,102,102);
          letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:11.0000pt;
          mso-font-kerning:1.0000pt;">Business entities and/or individuals who access a merchant's online store as a registered or unregistered member.</span><span
                  style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(51,51,51);
          font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
              </td>
            </tr>
          </table>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">2. Your Statement</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l9 level1 lfo1;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Our Platform is not intended for minors and may not be used by minors. By using our Platform, you represent that you are an adult and that you are legally able to enter into contractual agreements.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l9 level1 lfo1;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If you are using the Platform on behalf of an entity, by using the Platform you represent that you have the necessary right and authority to agree to these Terms, including our Privacy Policy and other documents referenced on behalf of that entity.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">3. Our Platform</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l19 level1 lfo2;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Our platform is a SaaS (Software as a Service) solution designed to help merchants build and operate their online stores to display their product and/or service catalog, sell products/services, facilitate customer payments and communicate transactions between merchants and their customers through proprietary third-party platforms (not limited to Whatsapp and Facebook). Merchants register to use the free version of our platform or subscribe to the premium version on a subscription basis ("Subscription Plan").</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l19 level1 lfo2;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">The stores created by merchants on the Oshopoo platform are open for customers to access, browse, and purchase the products and services they want. Customers can register as store members in the store to get a personalized shopping experience, or they can shop as a visitor without registering as a member.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">4. Your Account</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l21 level1 lfo3;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">When registering an account on our Platform as a merchant or customer or subscribing to a subscription plan for a personalized shopping experience, you will be required to provide certain information, including but not limited to your username, password, full name of the individual or registered user or business, password as appropriate, email address and valid telephone number ("Account Information"). Merchants are not required to provide a username. They may be identified by email, store name and/or store URL. You agree that all account information provided is true, accurate and current. You should choose a strong and secure password. You must ensure that your password is secure and confidential.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l21 level1 lfo3;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You agree not to share your account information or allow others to access your account. If we detect that an account is shared by multiple users, we may consider it a security breach and suspend, deactivate or terminate your account without notice. We have the right to suspend, deactivate or terminate any account at any time without liability if we reasonably believe that you have violated these Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l21 level1 lfo3;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You are responsible for all conduct or activity that occurs through or under your account, unless you promptly report abuse. If you allow others to use your account and/or account information, you are responsible for such users' activities related to your account and/or account information.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l21 level1 lfo3;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If you have forgotten your account information, or believe or know that someone has (attempted to) use your account and/or account information without authorization, you should notify us immediately at sales@thinkretail.com.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l21 level1 lfo3;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">All accounts are non-transferable. If you access or use an account on behalf of another person or entity, you represent that you are authorized to accept these Terms on that person or entity's behalf and that person or entity agrees to be responsible to us if you or that other person or entity breaches these Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l21 level1 lfo3;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Store names and user names must not be offensive or offensive to public decency, nor intended to deceive subscribers or convey a false impression of an association with Oshopoo. We reserve the right to modify any store name or user name without prior notice if we have reason to believe that the store name or user name violates these Terms; and/or</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l21 level2 lfo3;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>a.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Suspend and/or deactivate your account until the name is changed.</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">5. Availability of our Services</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l8 level1 lfo4;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We are constantly changing and improving our Platform and the products or services we offer. We may change or terminate any product or service functionality on our Platform, or add or remove functionality or features, from time to time, and we may suspend or discontinue certain products, services, functionality or features entirely. We reserve the right to make the above changes at any time without prior notice.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l8 level1 lfo4;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may release platform products, services, functions or features that we are still testing and evaluating. We will mark these as "beta," "preview," "early access," or "trial," or any words or phrases of similar meaning, indicating that the product, service, function or feature is under development. By continuing to use our platform, you acknowledge that these beta services may not be as reliable as other products or services we offer and may be subject to service interruptions, and you agree to be bound by the terms associated with these beta features.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l8 level1 lfo4;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We try our best to ensure that our platform is always available, but we do not guarantee or provide any guarantee that the operation of or access to our platform will be uninterrupted or continuous. Our platform may be interrupted due to maintenance, repair, upgrade, network or equipment failure. In any case, Oshopoo will not be responsible for any loss caused by the interruption or damage of the platform.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l8 level1 lfo4;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You are responsible for configuring your information technology, computer programs and platform or system in order to access our Platform. Oshopoo does not guarantee that the Platform will be free from errors and/or viruses and assumes no responsibility for any direct or indirect loss or damage caused by errors and/or viruses exposed by the use of our Platform.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">6. Use of the Platform</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level1 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">When using or accessing the Platform, you agree to comply with these Terms and all applicable laws and regulatory requirements, including those related to privacy and intellectual property protection.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level1 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">The Platform is currently provided to you free of charge, but commercial use is also subject to a subscription fee. Oshopoo reserves the right to modify or withdraw the Platform and/or the Platform fee structure at any time and for any reason under these Terms without prior notice and without incurring any liability.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level1 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In exchange for being a customer or merchant registered on the Platform or a merchant subscribing to a Plan, we grant the customer or merchant a personal, worldwide, royalty-free, non-assignable, non-transferable, non-sublicensable, non-exclusive and revocable license to access and use our Platform, including any software or applications provided as part of the Services we provide. This authorization is for the sole purpose of enabling you to use our Platform and enjoy its benefits as a customer or merchant, subject to these Terms and any additional terms specified for customers or merchants in Part B or Part C of these Terms, respectively.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level1 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If you fail to comply with these Terms or other Additional Terms, your permission to use our Platform will terminate.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level1 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You agree not to abuse the Platform or help others do so. You agree without reservation not to do any of the following in relation to our Platform: use our Platform for illegal or unauthorised purposes; copy, modify, distribute, sell, rent, lend or trade any access rights to the Platform or any data or information on the Platform;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>a.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Resell or attempt to derive commercial benefit from any data, content or information contained in or provided on the Platform;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>b.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Probe, scan or test the vulnerability of any system or network;</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>c.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Violate or otherwise circumvent any security or authentication measures or limitations on use of the Services;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>d.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">access, tamper with or use non-public areas or portions of the Platform;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>e.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">interfere with or disrupt any user, host or network, for example by sending viruses, trojans, worms, logic bombs or any other malicious or technologically harmful material, overloading, flooding, spamming or mail bombing any part of the Platform or scripting any content in any way that interferes with or creates an undue burden on the Platform;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>f.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Reverse engineer, decompile, disassemble, crack or otherwise attempt to obtain the source code of the Platform or any non-open source related technology;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>g.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Access, search for, or create Platform accounts by any means (automated or otherwise) other than through our publicly supported interfaces (e.g., "scraping" or mass creation of accounts) or attempt to do so;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>h.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Send unsolicited communications, promotions or advertisements or spam;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>i.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Forge any TCP/IP packet header or any part of the header information in any email;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>j.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Send altered, deceptive or false source identification information, including "spoofing" or "phishing";</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>k.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Conduct surveys, contests or pyramid schemes without proper authorization, or promote or advertise products or services;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>l.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Abuse of recommendations or promotions;</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>m.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Post, publish, upload, display, distribute or share any material that is unlawful, inappropriate, obscene, pornographic, obscene, indecent, defamatory, libelous, abusive or intentionally false and/or infringing on intellectual property rights;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>n.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">violate the letter or spirit of our Terms;</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>o.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In any way that violates applicable laws or regulations; and/or</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l22 level2 lfo5;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>p.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Invade the privacy or violate the rights of others.</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">7. Your Rights</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l4 level1 lfo6;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We hereby grant you a worldwide, limited, non-exclusive, non-transferable, non-licensable, non-sublicensable, royalty-free, revocable license to use our Platform for personal or business purposes in accordance with these Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l4 level1 lfo6;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You may not assign your rights under these Terms without our prior written consent. We may assign these Terms and delegate certain duties, obligations and responsibilities under or in connection with these Terms at our sole discretion.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l4 level1 lfo6;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We reserve all rights, title and interest not expressly granted in the license above to the extent possible under applicable law.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l4 level1 lfo6;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You retain all rights to any information or content you submit, post or display on or through the Platform ("Your Content"). By submitting, posting or displaying such Content, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, process, copy, reproduce, adapt, modify, publish, transmit, display and distribute such Content in any and all media or through any distribution channels (now known or later developed), subject to the applicable terms of our Privacy Policy.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l4 level1 lfo6;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You are responsible for the use of your content and any consequences thereof, including any consequences of the use of your content by other users or third parties. We assume no responsibility for any use of your content or any content or information submitted or posted by other users or visitors.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l4 level1 lfo6;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You warrant that Your Content does not and will not infringe the rights of any third party and that you have all necessary rights, power and authority to perform our obligations under these Terms with respect to Your Content.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">8. Our Rights</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">We may (at our sole discretion but without obligation) review content or information submitted or posted by users on our Platform. We reserve the right to remove any content that we consider to be offensive, harmful, deceptive, discriminatory, defamatory or otherwise inappropriate or misleading, or that we believe may infringe the rights of a third party. We do not endorse or support any views expressed by any user on our Platform.</span></b><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">9. Intellectual Property Rights</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l12 level1 lfo7;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Our name "Oshopoo" and our marks and logos are our trademarks (whether registered or not) and may not be used without our prior express written consent.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l12 level1 lfo7;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">All trademarks, trade names, service marks, logos, copyrights, database rights and other intellectual property rights of any nature on the Platform, including all information, data, text, music, sounds, images, photographs, graphics, illustrations and video messages, page titles, button icons, scripts, the arrangement and presentation thereof and the underlying software code, source code, software compilations, tools, applications, slogans, filters, user-generated filters and other content contained therein (collectively, the "Materials") are owned directly by us. You acknowledge that you have no ownership rights in any (part of) the Materials. We reserve all rights to take any legal action in respect of any rights applicable to the Materials.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l12 level1 lfo7;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">All intellectual property rights in the Platform or in the products or services we provide belong to us or have been legally licensed to us. All rights under applicable laws are hereby reserved.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l12 level1 lfo7;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You may view, use, print or download extracts of the materials for your personal use but you must not otherwise use, copy, edit, alter, modify, reproduce, publish, display, distribute, store, transmit, commercially exploit or broadcast any of the materials in any form or in any way that creates the impression that such items belong to or are associated with you without our express written permission.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l12 level1 lfo7;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Reporting Intellectual Property Infringement: We have a policy of limiting access to our platform and terminating the accounts of users who infringe the intellectual property rights of others. If you believe that any content appearing on our platform infringes any copyright that you own or control, you may notify us at tech@oshopoo.com.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l12 level2 lfo7;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>a.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Please note that if you knowingly misrepresent that any activity or content or material on our Platform is infringing, you may be liable to us for costs and damages pending identification.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">10. Dispute Resolution</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Oshopoo encourages Customers and Merchants to resolve disputes (including refund claims) directly with each other whenever possible. Oshopoo does not assume any role in mediating disputes between Customers and Merchants.</span></b><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">11. Third-party software integration</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Our platform may be integrated with third-party software to provide a full set of functions for our users. We are not responsible for any problems and/or direct or indirect losses arising from the use of any third-party software. Your access and use of third-party software is subject to the terms of service and/or user agreement of the software.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">12. Platform Advertisers</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We are not responsible for the advertisements on the Platform. If you agree to purchase goods and/or services from any Merchant or third party that advertises on the Platform, you do so at your own risk. The advertisers and the Merchants (if the goods or services are purchased from or through one of the Merchants) (and not Oshopoo) are responsible for such goods and/or services and your only recourse is against them if you have any questions or complaints in relation to the same.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">13. Limitation of Liability</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level1 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">To the maximum extent permitted by law, we (including our holding companies, subsidiaries, affiliates, directors, officers, employees, agents, representatives, partners and licensors (collectively, “Our Entities”)) expressly limit our liability arising out of or in connection with the provision of the Platform as follows: We provide the Platform and any products or services we provide on an “as is” and “as available” basis and you access or use our Platform at your own risk;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level2 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>a.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We make no warranties, representations or guarantees of any kind (whether express or implied) regarding the Platform and any products or services offered on our Platform;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level2 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>b.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We do not guarantee that the information or content you find on the Platform is always accurate, true, complete and up-to-date;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level2 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>c.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We expressly disclaim all warranties and representations (such as the warranties of merchantability, fitness for a particular purpose and non-infringement);</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level2 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>d.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We are not responsible for any delays or interruptions in our Platform or for any defects, viruses, errors or bugs; and</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level2 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>e.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We are not responsible for the conduct of any user of the Platform (whether online or offline) or for any content or information submitted or posted.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level1 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Our role in managing the Platform is purely mechanical and administrative. To the maximum extent permitted by law, our entities shall not be liable to you or any other party for: any indirect, incidental, special, exemplary, consequential or punitive damages arising out of or in connection with the use of the Platform;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level2 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>a.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">and/or any loss of data, business, opportunity, reputation, profits or revenue.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level1 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Nothing in these terms will limit your legal rights as a consumer that you cannot waive by contract.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l7 level1 lfo8;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Except for types of liability that we cannot limit at law, our liability to you (in the aggregate) is limited to the amount you paid to us (if any) for your use of our Platform or any products or services we provide on it. Two (12) months.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">14. Indemnity</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l14 level1 lfo9;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You agree to indemnify and hold our Entities harmless from and against all liabilities, damages, claims, costs (including legal fees and expenses) and expenses related to or arising out of: your breach of these Terms,</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l14 level2 lfo9;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>a.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Any sales and purchases between customers and merchants,</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l14 level2 lfo9;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>b.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Your use of our Platform and/or</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l14 level2 lfo9;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>c.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Any false statements you make on our Platform.</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l14 level1 lfo9;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You also agree to fully cooperate with us in the defense or settlement of any claim related to or arising out of our Platform or these Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l14 level1 lfo9;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We reserve the right, at your expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and you agree to cooperate in such defense. We will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it. No settlement that may adversely affect our rights or obligations will be made without our prior written approval.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l14 level1 lfo9;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You agree to notify us immediately of any third party claim, cooperate with us in our defense of such claim, and pay all fees, costs and expenses associated with our defense of such claim, including but not limited to attorneys' fees. You agree not to settle any matter for which you have an indemnification obligation to us without our prior written consent.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">15. Termination</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level1 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We reserve the right and ability to terminate, withdraw, suspend your access to or use our Platform or any part thereof, and to control who can use the Platform and request certain content or information provided, at any time and at our sole discretion, without notice. We may also modify, suspend or terminate the Platform. We are not liable for any loss or damage arising from our termination of your access to or use of our Platform.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level1 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If you are a Subscribing Merchant or Registered Customer, you may cease using the Platform at any time by deactivating your account, or simply cease accessing or using our Platform.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level1 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We reserve the right to suspend or terminate your access to our Platform if we have reasonable grounds to believe that: you have seriously or repeatedly breached these Terms (including persistent failure to pay any amounts);</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level2 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>a.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You use the Platform in a way that creates a real risk of harm or loss to us, other users or the public;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level2 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>b.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We are required to do so by a governmental or regulatory agency or as required by applicable law, regulation or legal process; or</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level2 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>c.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">It is no longer possible for us to provide the Platform to you and it is no longer commercially viable.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level1 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In any of the above cases, we will attempt to notify you at the email address included in your account information or the next time you attempt to access your account, unless we are prohibited by law from notifying you.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level1 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Upon termination of your access rights, these Terms will also terminate, with the exception of Sections 7 to 15.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l13 level1 lfo10;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We will report any violation of these Terms to law enforcement authorities where we believe necessary or appropriate and we will cooperate with those authorities by disclosing your identity and providing them with any information we have about you in our systems.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">16. System Requirements</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l0 level1 lfo11;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In order to use the Platform, you will need a compatible mobile device, desktop or laptop computer, Internet access and the necessary minimum specifications (the "System Requirements").</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l0 level1 lfo11;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">System requirements are as follows: The app requires a device running Android 4.4 and/or iOS 9.0 or higher.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l0 level1 lfo11;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">The software functionality and system requirements of our platform may be upgraded from time to time to add support for new features and services.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">17. Entire Agreement</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l5 level1 lfo12;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">These Terms constitute the entire agreement between any user and us in relation to the use of the Platform or any transactions on the Platform. These Terms supersede and extinguish all other agreements, promises, assurances, warranties, representations and understandings, whether written or oral, between any user and us in relation to the use of the Platform or any transactions on the Platform.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l5 level1 lfo12;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You acknowledge that you will have no remedies in respect of any statements, representations, warranties or assurances (whether made intentionally or negligently) that are not set out in these Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">18. Severability</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l3 level1 lfo13;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If any provision of these Terms is held to be unlawful, void, invalid or unenforceable, then that provision will be deemed severable from these Terms and will be construed as nearly as possible to reflect the parties’ intentions as reflected in the provision and all other provisions will remain in full force and effect.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l3 level1 lfo13;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Oshopoo's failure to exercise or enforce any right or provision of these Terms does not constitute a waiver of such right or provision unless acknowledged and agreed to by Oshopoo in writing.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">19. Changes to the Terms</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may modify these Terms from time to time by posting updated Terms on our Platform. If we make material changes, we will notify you before the changes take effect. Your continued use of our Platform and Services after the changes take effect will constitute your agreement to be bound by the revised Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">20. Miscellaneous</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l6 level1 lfo14;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may transfer our rights and obligations under these Terms to another organisation. If you do not wish to continue any contract with us with the transferee, you must notify us within fourteen (14) business days so that we can refund any prepaid amounts you have paid for products or services you have purchased but not yet received. Failure to notify us within 14 days may result in the forfeiture of any outstanding amounts.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l6 level1 lfo14;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You may only transfer your rights or obligations under these Terms to another person with our express written consent.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l6 level1 lfo14;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If a court or relevant authority finds any of these terms to be invalid or illegal, the remaining terms will continue to be valid. Each of these terms operates separately. If any court or relevant authority decides that any of these paragraphs is unenforceable, the remaining paragraphs will remain in full force and effect.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l6 level1 lfo14;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Neither party will be liable to the other for any breach of contract caused by force majeure (including any circumstances beyond the reasonable control of either party).</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l6 level1 lfo14;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">By using our Platform, you consent to receive communications from us electronically. You agree that all notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. You agree that you have the ability to store electronic communications so that you can access them in an unaltered form.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l15 level1 lfo15;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If we do not insist immediately that you do anything you are required to do under these Terms, or if we delay taking steps against you for your breach of any term of this Agreement, our delay or failure will not mean that you do not have to observe or perform any term hereof and it will not prevent us from taking steps against you to enforce those terms at a later date.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">21. Applicable Law and Jurisdiction</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l2 level1 lfo16;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">These Terms shall be governed by and construed in accordance with the laws of the Hong Kong Special Administrative Region.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l2 level1 lfo16;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">The courts of the Hong Kong Special Administrative Region shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">22. Feedback</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l20 level1 lfo17;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We value and welcome feedback on our platform. You agree that we are free to use, disclose, adopt and/or modify any feedback and any information (including any ideas, concepts, proposals, suggestions or comments) you provide in connection with our platform or any of our products or services. Without paying any fees to you. For any questions or issues related to our platform, our products or services or these terms, you can email us at tech@oshopoo.com</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
mso-list:l17 level1 lfo18;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You hereby waive and agree to waive any right to claim any fees, royalties, charges or other payments with respect to our use, disclosure, adoption and/or modification of any of your Feedback.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h2 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;
line-height:130%;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
line-height:130%;color:rgb(85,85,85);letter-spacing:0.0000pt;
mso-ansi-font-weight:bold;mso-ansi-font-style:normal;font-size:25.5000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);">SECTION B – CUSTOMER SERVICE TERMS</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:16.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h2>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In addition to these Terms, the following additional terms apply to all customers:</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">1. Purchase from stores recommended on our platform</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Customers should place orders with merchants by following the instructions provided on our platform. All orders placed by a Customer to a Merchant are considered an offer and the Merchant accepts the order when our Platform sends you a confirmation email. At this point, a contract between the Customer and the Merchant is formed, but may be subject to the Merchant's separate terms and conditions and/or terms of service (if any). We are not responsible or liable to any person or entity in connection with the sale or purchase of any goods or services on our platform. We are not responsible for enforcing any contractual obligations between the merchant and the customer or between the customer and any third party responsible for fulfilling any purchase. We disclaim all liability for any direct and/or indirect damages arising out of or in connection with any sales and purchases.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Once a customer places an order and the merchant confirms shipment or fulfillment of the order, we will send emails to customers to obtain feedback about the goods and/or services provided. We appreciate any feedback provided by customers, which will be visible to other customers who visit the merchant's store. We will not be responsible for any claims arising from or related to the content of any feedback.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Oshopoo has no control over and therefore cannot guarantee or warrant the existence, quality, safety or legality of the products or services offered or advertised on our platform, the accuracy, completeness or authenticity of any information, content or listings posted by any merchant, and/or the credibility of users, any merchants and/or users, the ability of merchants to sell or customers to purchase, whether specific products and/or services are suitable for a specific purpose or whether specific merchants and customers will complete a specific transaction.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You can find company information related to each merchant on the merchant's website, including company background, store addresses, contact emails and phone numbers. For any questions or problems related to the merchant's website, any products or services, you can contact the merchant by calling the merchant, sending emails or online messages.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We do not participate in any transactions between merchants and customers, our role is only to provide a platform where merchants' stores can be displayed through the use of our platform, where our platform acts as a marketplace for merchants and customers. We do not process payments or participate in fulfilling purchases on behalf of merchants. Customers should choose their preferred payment method from those offered by the merchant. Customers must choose their preferred fulfillment method, including delivery methods offered by the merchant. All payment and delivery arrangements are governed by the merchant's terms of sale and the relevant terms of the electronic payment provider and fulfillment service provider (if any). In any case, we are not responsible for any problems or losses arising from and/or related to payment and delivery. Therefore, our role is only mechanical and administrative in nature as we provide our platform for merchants to set up and operate their online stores.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">When purchasing goods and/or services through our platform, you agree to abide by our applicable rules and policies in effect from time to time. It is your responsibility to read these terms, our Privacy Policy and other applicable rules and policies of the relevant merchants (collectively referred to as "Governing Terms") before committing to order any goods and/or services. Placing any order is deemed to be your agreement to the Governing Terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We are not an agent of any client, merchant or third party.</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may, at our sole discretion (but we have no obligation) to inspect, review and/or monitor any listings, comments and/or information contained in comments posted by any Client without notifying the relevant Client.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">The images of products and/or services on the Online Store created by the Platform are for illustrative purposes only. The products and/or services you purchase may differ from these images.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l23 level1 lfo19;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Our platform is intended only for the execution and purchase of goods and/or services in the countries in which we operate and is not limited to Hong Kong. Our mobile application is easily accessible and can be downloaded from anywhere in the world.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">2. Customer's right to change order</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If any Customer decides for any reason to make changes to an order that has been placed with the Merchant (a "Change"), the Customer should contact the Merchant directly. The Merchant's terms of sale shall apply to the changes and Oshopoo shall not be involved in the changes and shall not be liable for any claims arising out of or in connection with the changes.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">3. Customer’s right to terminate the contract</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If the Customer wishes to terminate the contract to purchase any products or services for reasons not limited to defects in all or part of the goods and/or services provided ("Termination"), he or she should contact the Merchant directly. The Termination shall be governed by the Merchant's Terms of Sale, and Oshopoo is not involved in the Termination and shall not be liable for any claims arising from or related to the Termination.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">4. Product Returns and Refunds</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l16 level1 lfo20;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Customers have the right to return any product purchased from a Merchant, but this right shall be subject to the terms of the Merchant's return and refund policy.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l16 level1 lfo20;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Customers have the right to request a refund for any products returned to the Merchant, but this right is subject to the terms of the Merchant's own returns and refunds policy.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l16 level1 lfo20;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Oshopoo plays no role in facilitating returns, requesting refunds, or intervening in returns or refunds on behalf of customers.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">5. Merchants’ use of customers’ personal information</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l11 level1 lfo21;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">The Merchant is the information controller and processor and will collect and use any personal information provided by the Customer through the Platform for purposes not limited to the following: to provide you with products and/or services;</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l11 level2 lfo21;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#161;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Process payment and delivery of your products and/or services; and</span><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:24.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l11 level2 lfo21;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#161;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">With your consent, provide you with product updates and promotional information provided by merchants</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l11 level1 lfo21;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Any merchant's use of personal information provided by customers is subject to the merchant's own terms of sale, terms of service and/or privacy policy, and Oshopoo is not involved in them.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l11 level1 lfo21;">
          <span style="font-family:wingdings;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span style='mso-list:Ignore;'>&#108;
            <span>&nbsp;</span></span></span>
            <span
              style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Merchants are responsible for how to store and process your personal information collected by the platform. If you want to access, update or delete your personal information, you should contact the merchant. Oshopoo will not store or process any personal information other than the information contained in the account information, and such information will never be shared with merchants or third parties and is subject to the Oshopoo Privacy Policy.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h2 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;
line-height:130%;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
line-height:130%;color:rgb(85,85,85);letter-spacing:0.0000pt;
mso-ansi-font-weight:bold;mso-ansi-font-style:normal;font-size:25.5000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Section C – Merchant Terms of Service</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:16.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h2>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">In addition to these Terms, the following additional terms apply to all Merchants:</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">1. Subscription Terms</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l1 level1 lfo22;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">When you initially sign up for a Subscription Plan, you will be immediately charged for an initial term of 1 month, 1 year or 2 years at the then-current Subscription Fee for the applicable Subscription Tier. Your subscription will automatically renew upon expiration of the initial term and/or at the end of each subsequent renewal term at the then-current Subscription Fee unless you terminate your Subscription Plan.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l1 level1 lfo22;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">By subscribing to a subscription plan, you authorize Oshopoo to charge the applicable recurring subscription fees through your designated billing payment method.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l1 level1 lfo22;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We will charge you in advance for the renewal term of your Subscription Plan on the applicable billing date (the "Billing Date"). Subscription fees charged on the Billing Date apply to subscription periods following the Billing Date (e.g., the calendar year following the Billing Date).</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l1 level1 lfo22;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may increase the price payable for any subscription plan and will notify you of the latest subscription fee 30 days before the start of the renewal term, which will take effect on the first day of the renewal term. If you do not cancel your subscription, you will be deemed to have accepted the latest subscription fee for that renewal term and any subsequent renewal terms.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l1 level1 lfo22;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may also reduce the subscription fee, which will be effective at the next renewal term, and no prorated repayment will be made for the prior subscription period covered by the reduced subscription fee.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l1 level1 lfo22;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You can update any of your billing information (including changing your desired billing payment method) through your account settings or by contacting Oshopoo at sales@thinkretail.com.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l1 level1 lfo22;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>7.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If a dispute arises between the Merchant and Oshopoo regarding any issue arising out of or related to the Subscription Service, Oshopoo shall have full discretion to determine the issue and its decision shall be final.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">2. Additional Guarantee from the Merchant</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l10 level1 lfo23;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>8.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Merchants are solely responsible for all advertisements, promotions, loyalty-based offers (each, an “Offer”) and all other content including but not limited to brands, trademarks, logos, data, texts, images, graphics published by them on our Platform, provided to customers.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l10 level1 lfo23;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>9.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Offers and other content are provided by and determined solely by the Merchant. The Merchant is solely responsible for ensuring the accuracy, reliability, completeness, quality and validity of the Offers and content.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l10 level1 lfo23;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>10.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You represent and warrant that you own or have the necessary licenses, rights, consents, permissions and releases to your Content and to grant the licenses and rights set forth in these Terms as they apply solely to Merchants. You are also responsible for any fees, royalties or other amounts owed as a result of any Content you provide.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">3. Billing license for free trial</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">From time to time, we may offer free trials of subscription plans. To sign up for a free trial, you may be required to provide an applicable billing payment method. If you subscribe to a free trial, you agree to grant Oshopoo a pre-authorization to charge the subscription fee applicable to that subscription plan when you continue to use the subscription plan after the free trial expires. Some financial institutions may treat these requested amounts as actual pending charges. These are not actual charges and Oshopoo is not responsible for any consequences that may arise on your account as a result of such authorization, such as overdraft fees.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <h3 style="margin-top:0.0000pt;margin-bottom:8.0000pt;text-indent:0.0000pt;"><b
            style="mso-bidi-font-weight:normal"><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:20.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">4. Cancellation of Subscription Plan</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h3>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l18 level1 lfo24;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>11.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You can cancel your subscription at any time by following the instructions specified on our Platform. Once you cancel your subscription, Oshopoo will suspend the renewal of automatic subscription fees for you until you resubscribe. All fees and charges paid prior to termination are non-refundable. Termination of your subscription does not relieve you of any obligation to pay accrued fees. Your subscription plan will terminate the day after the last charging cycle.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l18 level1 lfo24;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>12.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If you re-subscribe while your existing subscription plan is active, your existing subscription plan will end on the original expiration date and your subscription will be extended to the new expiration date.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l18 level1 lfo24;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>13.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You agree that Oshopoo may freeze or terminate your subscription plan at its sole discretion without prior notice for various reasons (including but not limited to your breach of these terms).</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;
mso-list:l18 level1 lfo24;">
          <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>14.<span>&nbsp;</span></span></span>
            <span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Oshopoo may also modify or terminate any subscription plan or any part thereof at any time without prior notice at its sole discretion. In addition, the merchant hereby agrees to hold harmless any claims or direct or indirect losses arising from or related to the disruption or termination of the merchant's access to the subscription plan.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:41.6000pt;"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(255,0,0);font-size:11.0000pt;mso-font-kerning:1.0000pt;">Oshopo is not responsible to you or any third party.</span><span
            style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(255,0,0);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
          <p className="MsoNormal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
          <p className="MsoNormal"><span style="font-family:DINPro-Medium;mso-fareast-font-family:微软雅黑;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
        </div>
`
        }}>
        </div>
      </div>
    </section>
  </>
}
