import './index.css'

export default function Legal() {

  return <>
    <section className={`section bg-[#0A282A]`}>
      <div className={`container  flex flex-col items-center pb-[58px] text-center text-white`}>
        <h3 className="font-bold text-4xl my-[60px] text-white" style={{ fontFamily: 'DINPro-Bold, DINPro' }}>
          Privacy Policy</h3>
      </div>
    </section>
    <section className={`section bg-white`}>
      <div className={`container `}>
        <div className="py-[20px] md:py-[58px]" dangerouslySetInnerHTML={{
          __html: `<div class="Section0" style="layout-grid:19.3500pt;">
    <p class="MsoNormal" align=center style="margin-right:0.0000pt;margin-bottom:24.0000pt;margin-left:0.0000pt;
text-align:center;"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;
padding:0pt 0pt 0pt 0pt ;"><span style="font-family:DINPro-Medium;
color:rgb(255,0,0);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">This Privacy Policy describes </span><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">how we collect, use and share your personal information when you visit Oshopoo or make purchases or purchases under the domain and subdomains of www.oshopoo.com (the “Website”).</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h4 style="margin-top:0.0000pt;margin-bottom:8.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:18.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Personal Information We Collect</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h4>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may collect personal information when you browse Oshopoo, register for Oshopoo services, use Oshopoo services, or otherwise provide us with information. Your personal information will be collected for legitimate purposes directly related to the Oshopoo features or activities described in the Terms or this Privacy Policy. The provision of information is always voluntary, but please understand that you may not be able to use certain services until you provide the required information.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We will not sell or disclose your name, email address, or other personal information to third parties without your explicit consent, except as described in this Privacy Policy. Sometimes, we may be required by law to share your personal information with a court or regulatory agency (for example, if we receive a court order requiring disclosure of information). We will try to notify you in advance of such sharing unless we are prohibited by law from doing so.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">As an Oshopoo user, you need to provide a valid email address when registering and choose a username or alias that can represent your identity on Oshopoo.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
padding:0pt 0pt 0pt 0pt ;text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Depending on the services you choose to use, we may request additional information such as business address, business type, business identification number, bank account information, billing address, telephone number and/or physical mailing address.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may collect personal information when you register for Oshopoo services, use Oshopoo services, or otherwise provide us with information. The collection of your personal information will be used for legitimate purposes directly related to the Oshopoo features or activities described in the Terms or this Privacy Policy. The provision of information is always voluntary, but please understand that you may not be able to use certain services until you provide the required information.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We will not sell or disclose your name, email address, or other personal information to third parties without your explicit consent, except as described in this Privacy Policy. Sometimes, we may be required by law to share your personal information with a court or regulatory agency (for example, if we receive a court order requiring disclosure of information). We will try to notify you in advance of such sharing unless we are prohibited by law from doing so.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>7.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">As an Oshopoo user, you need to provide a valid email address when registering and choose a username or alias that can represent your identity on Oshopoo.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>8.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Depending on the services you choose to use, we may request additional information such as business address, business type, business identification number, bank account information, billing address, telephone number and/or physical mailing address.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>9.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may contact users on a confidential basis to request more information about their identity, business, or items listed on Oshopoo to ensure compliance with our rules and policies.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>10.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You may choose to provide your full name and other personal information (e.g., birthday, gender, location) to other users associated with your account and activities on Oshopoo.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>11.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Your username or alias will be displayed publicly and associated with your Oshopoo activities. Others may see your joining date, feedback, ratings, profile information, listings, likes, followers, and comments on Oshopoo.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>12.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">When you use Oshopoo, we automatically receive and record some information from your browser, such as your IP address, cookies, and data about the pages you visit on Oshopoo through the use of log files provided by our third-party tracking utilities partners. In addition, when you browse this website, we collect information about the individual web pages or products you view, which websites or search terms refer you to this website, and information about how you interact with this website. We call this automatically collected information "Device Information".</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>13.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">This information helps us analyze and understand how Oshopoo provides services to users and visitors, and provide users and visitors with a more personalized experience.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>14.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Some users or visitors may use external third-party applications (such as Facebook) to connect or register with Oshopoo accounts.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>15.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may receive some data and information from these connected third-party applications, and the data and information we receive from these channels are also subject to this Privacy Policy. Connecting your Oshopoo account to third-party applications or services is optional.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>16.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may receive or collect information (such as email addresses) from non-registered Oshopoo users (“Non-Users”) in connection with certain Oshopoo features, including but not limited to: Non-Users subscribing to Oshopoo newsletters, Users inviting Non-Users to visit the Website or download applications to their mobile devices, or Non-Users participating in transactions.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>17.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Non-User Information is subject to this Privacy Policy and will be used only for the purpose for which it was originally submitted to us.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>18.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We use the information described in this Privacy Policy to provide and improve our services, for billing and payment, for identification and authentication purposes, to contact members or interested parties, and for general research and aggregate reporting. In addition, we may use such information for fraud and risk monitoring and to comply with legal requirements (such as "know your customer" requirements and applicable accounting and tax regulations).</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>19.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">If you collect any personal information from your customers, such as name, ID number, and address, you are responsible for obtaining your customers' explicit consent to use that information.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l0 level1 lfo1;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>20.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Oshopoo's terms and conditions require that all account owners be 18 years of age or older (or have the permission and supervision of a responsible parent or legal guardian), therefore, this policy does not discuss the use of Oshopoo or the Services by minors.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h4 style="margin-top:0.0000pt;margin-bottom:8.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:18.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Special precautions for children</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h4>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Children (under 18 years of age) are not eligible to use Oshopoo unsupervised, and we ask that children do not submit any personal information to us. It is our policy not to knowingly request or allow children under the age of 18 to provide their personal information for any purpose. If you are under the age of 18, you may only use Oshopoo under the supervision of your parents or legal guardians.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h4 style="margin-top:0.0000pt;margin-bottom:8.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:18.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">How we use your personal information</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h4>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l1 level1 lfo2;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>1.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We use your personal information to facilitate the services you request. We use the personal information in the files we maintain about you and other information obtained from your current and past activities on Oshopoo to: (</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">i) verify your identity (for example, to protect you from theft or fraud);</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(ii) dispute resolution;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(iii) problem solving;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(iv) helping to facilitate secure transactions;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(v) collecting outstanding fees;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(vi) measure consumer interest in our products and services;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(vii) notify you of online and offline offers, products, services and updates;</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(viii) customize your experience;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(ix) assist with direct marketing, advertising or other communications;</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(x) detect and protect you and us from error, fraud and other criminal activity, and monitor regulatory risks;</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(xi) investigate potential illegal activities and threats to life and property and breaches of contracts (such as these Terms);</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(xii) performance of terms and conditions;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(xiii) comply with legal requirements;</span><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">(xiv) As otherwise disclosed to you at the time of collection or in other circumstances where we obtain your explicit consent. Sometimes, we may survey multiple users to identify issues or resolve disputes. In particular, we may examine your personal information to identify users who use multiple user IDs or aliases.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l1 level1 lfo2;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>2.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may compare and review your personal information for errors, omissions and accuracy and contact you for further documentation or clarification.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l1 level1 lfo2;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>3.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">You agree that we may use your personal information to improve our marketing and promotional efforts, analyze Oshopoo usage, improve our content and product offerings, and customize Oshopoo content, layout, and services.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l1 level1 lfo2;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>4.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">These uses improve Oshopoo and better tailor it to meet your needs in order to provide you with a smooth, efficient, secure and customized experience when using Oshopoo.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l1 level1 lfo2;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>5.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Certain services provided through Oshopoo may be provided by third parties. By using any products, services or features originating from this website, application, linked website or Oshopoo, you acknowledge and agree that we may share such information and data with any third party with whom we have a contractual relationship to provide the products, services or features requested by Th!nkShops' users and customers.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:9.6000pt;margin-left:20.8000pt;
text-align:left;mso-list:l1 level1 lfo2;"><![if !supportLists]><span style="font-family:DINPro-Medium;color:rgb(68,68,68);
letter-spacing:0.0000pt;mso-ansi-font-style:normal;font-size:12.0000pt;
mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><span
            style='mso-list:Ignore;'>6.<span>&nbsp;</span></span></span><![endif]><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Many Oshopoo users also use non-Oshopoo services to promote and support their businesses. If we share your information with non-Oshopoo services in accordance with your instructions, we cannot control how these services use your information, and you should review the privacy policies of these services to evaluate whether they meet your expectations.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We use the Order Information we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging shipping, and providing you with invoices and/or order confirmations).</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We use the Device Information that we collect to help us screen for potential risks and fraud (in particular, your IP address) and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h4 style="margin-top:0.0000pt;margin-bottom:8.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:18.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Share your personal message</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h4>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We may share your personal information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h4 style="margin-top:0.0000pt;margin-bottom:8.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:18.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Data Retention/Deletion</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h4>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We will retain all your information (including personal data and order information) for our records unless you ask us to delete it.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">Request that your information be deleted from our database. We will perform the deletion within 30 business days and send you a confirmation message once the information has been deleted. Once deleted, it cannot be restored at any time.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h4 style="margin-top:0.0000pt;margin-bottom:8.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:18.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Changes</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h4>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">We reserve the right to modify this Privacy Policy at any time, so please review it regularly.</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <h4 style="margin-top:0.0000pt;margin-bottom:8.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(85,85,85);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
mso-ansi-font-style:normal;font-size:18.0000pt;mso-font-kerning:1.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);">Contact Us</span></b><b
            style="mso-bidi-font-weight:normal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;"></span></b></h4>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">&nbsp;</span><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(68,68,68);letter-spacing:0.0000pt;mso-ansi-font-style:normal;
font-size:12.0000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);">For more information about our privacy practices, if you have questions or would like to make a complaint, please email teah@oshopoo.com</span><span
            style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class="MsoNormal" style="margin-top:0.0000pt;margin-bottom:20.8000pt;text-indent:0.0000pt;"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
    <p class="MsoTitle" style="text-align:left;"><b><span style="font-family:DINPro-Medium;
mso-bidi-font-family:'Times New Roman';color:rgb(51,51,51);font-weight:bold;
font-size:24.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></b></p>
    <p class="MsoNormal"><span style="font-family:DINPro-Medium;
color:rgb(51,51,51);font-size:11.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
</div>
`
        }}>
        </div>
      </div>
    </section>
  </>
}
