import { BrowserRouter, Route, Routes, } from "react-router-dom";
import Home from './views/home/index.jsx';
import Layout from "./layout";
import Fun from './views/fun'
import './App.css'
import Legal from './views/legal'
import Privacy from './views/privacy'
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>}/>
          <Route path="fun" element={<Fun/>}/>
          <Route path="legal-agreement" element={<Legal/>}/>
          <Route path="privacy-policy" element={<Privacy/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
