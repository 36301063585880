import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const HomeHeader = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showModal])
  return <div className="section relative bg-[#0A282A] md:w-[1200px]">
    <div className="container">
      <div className={`header flex items-center`}>
        <img onClick={() => window.location.href = '/'} className=" w-[30vw] md:w-[142px]" src="/images/logo-simple.png"
             alt=""/>
        <div className="flex-1"></div>
        <div gap={12} className="flex items-center text-white font-bold  text-[4vw]  md:text-base">
          <button className="mx-[20px] button hidden md:inline-block" onClick={() => navigate('/fun')}>
            功能套餐
          </button>
          <button className="mx-[20px] button hidden md:inline-block">
            幫助中心
          </button>
          <button className="mx-[20px] button hidden md:inline-block"
                  onClick={() => window.location.href = 'http://admin.myoshopoo.com/login'}>
            登入
          </button>
          <button className=" button py-[2.5vw] md:py-[12px] ml-[20px] px-[16px] bg-[#2C695D] rounded-md"
                  onClick={() => window.location.href = 'http://admin.myoshopoo.com/register?username='}>
            免費試用14天
          </button>
          <div className="md:hidden ml-[6vw] px-[1vw] py-[2vw]" onClick={() => setShowModal(true)}>
            <div className="w-[5vw] h-[0.5vw] m-[1vw] bg-white rounded-full"></div>
            <div className="w-[5vw] h-[0.5vw] m-[1vw]  bg-white rounded-full"></div>
            <div className="w-[5vw] h-[0.5vw] m-[1vw]  bg-white rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
    <div className={`${showModal ? '' : 'hidden'} fixed w-[100vw] h-[100vh] bg-[#00000033]`} onScroll={(e) => {
      e.stopPropagation();
      e.preventDefault()
    }}>
      <div
        className="absolute flex flex-col justify-center items-center w-[72%] bg-white h-full right-0  text-black text-[4.5vw]">
        <img src="/images/close.png" className="absolute left-[6.5vw] top-[17.5vw] w-[7.5vw]" alt=""
             onClick={() => setShowModal(false)}/>

        <button className="mx-[20px] button my-[5vw]" onClick={() => {
          navigate('/fun')
          setShowModal(false)
        }}>
          功能套餐
        </button>
        <button className="mx-[20px] button my-[5vw]">
          幫助中心
        </button>
        <button className="mx-[20px] button my-[5vw]"
                onClick={() => {
                  window.location.href = 'http://admin.myoshopoo.com/login'
                }}>
          登入
        </button>
        <button
          className=" button py-[2.5vw] md:py-[12px] ml-[20px] px-[16px] bg-[#2C695D] rounded-md  my-[5vw] text-white"
          onClick={() => window.location.href = 'http://admin.myoshopoo.com/register?username='}>
          免費試用14天
        </button>
      </div>

    </div>
    <Link to="https://wa.me/85260222423" target="_blank" className="fixed right-[4vw] bottom-[4vw]" style={{zIndex: 100}}>
      <img src="/images/call-me.png" className="w-[16vw] md:w-[60px]" alt=""/>
    </Link>
  </div>
}
export default HomeHeader
